<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <b-form class="d-flex flex-column" @submit.prevent="submit">
                <b-form-group label="عنوان راهنما">
                    <b-form-input
                            type="text"
                            placeholder="عنوان"
                            v-model="formData.title"
                    />
                </b-form-group>

                <b-form-group label=" کد امبد آی فریم ویدئو مورد نظر">
                <span slot="label">
                    جهت قرار دادن فایل ویدئو از سایت آپارات یا مشابه آن کد امبد آی فریم ویدئو مورد نظر را در فیلد زیر وارد نمایید و کد آی فریم قابل استفاده خروجی را در قسمت - اضافه کردن فایل تصویری (
                    <svg class="fr-svg" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                         width="25px">
                        <path
                                d="M15,8v8H5V8H15m2,2.5V7a1,1,0,0,0-1-1H4A1,1,0,0,0,3,7V17a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V13.5l2.29,2.29A1,1,0,0,0,21,15.08V8.91a1,1,0,0,0-1.71-.71Z"></path>
                    </svg>

                    ) -> کد جاسازی شده (
                    <svg width="25px" class="fr-svg" focusable="false" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M9.4,16.6,4.8,12,9.4,7.4,8,6,2,12l6,6Zm5.2,0L19.2,12,14.6,7.4,16,6l6,6-6,6Z"></path>
                    </svg>
                    )  - قرار دهید و دکمه اضافه کردن را وارد نمایید.
                </span>
                    <b-form-input
                            @input="getIframe"
                            type="text"
                            v-model="text"
                            placeholder="متن حاوی کد آی فریم (<iframe ...... </iframe>)"
                    />
                </b-form-group>
                <b-form-group label="کد آی فریم قابل استفاده">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button @click="copy" variant="info">کپی</b-button>
                        </b-input-group-prepend>
                        <b-form-input
                                type="text"
                                v-model="output"
                                readonly
                                placeholder="<iframe ... </iframe>"
                        />
                    </b-input-group>
                </b-form-group>

                <b-form-group label="محتوی راهنما">
                    <froala v-model="formData.content" :config="config"/>
                </b-form-group>
                <b-button variant="primary" type="submit">{{typeof id === 'undefined' ? 'افزودن' : 'ذخیره'}}</b-button>
            </b-form>
            <b-form-group class="p-1 m-1 border" label="نمونه خروجی فایل راهنما">
                <froalaView v-model="formData.content" :config="config"/>
            </b-form-group>
        </b-overlay>
    </b-card>
</template>

<script>

    import {
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BOverlay,
    } from 'bootstrap-vue'

    export default {
        name: "NewHelp",
        components: {
            BCard,
            BButton,
            BForm,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupPrepend,
            BOverlay,
        },
        props: ['id'],
        data() {
            return {
                isFile: 'editor',
                formData: {
                    name : 'HELP',
                    title: '',
                    content: '',
                },
                config: {
                    events: {
                        'initialized': function () {
                            console.log('floara inited')
                        },
                        'image.beforeUpload': function (files) {
                            let editor = this;
                            if (files.length) {
                                // Create a File Reader.
                                let reader = new FileReader();
                                // Set the reader to insert images when they are loaded.
                                reader.onload = function (e) {
                                    let result = e.target.result;
                                    editor.image.insert(result, null, null, editor.image.get());
                                };
                                // Read image as base64.
                                reader.readAsDataURL(files[0]);
                            }
                            editor.popups.hideAll();
                            // Stop default upload chain.
                            return false;
                        }
                    },
                    language: 'fa',
                    direction: 'rtl',
                    tooltips: false,
                    toolbarButtons: {
                        'moreText': {
                            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
                        },
                        'moreParagraph': {
                            'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
                        },
                        'moreRich': {
                            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo', 'fullscreen', 'print', 'selectAll', 'html', 'help'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },
                    fontFamily: {
                        'Yekan': 'یکان',
                    },
                    fontFamilyDefaultSelection: 'Yekan',
                    fontFamilySelection: false,
                    key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
                    attribution: false // to hide "Powered by Froala"
                },
                text: '',
                output: '',
            }
        },
        methods: {
            getIframe() {
                let a = this.text.match(/<iframe(.*?)<\/iframe>/)
                if (a) {
                    this.output = a[0]
                    this.copy()
                }
            },
            async copy() {
                await navigator.clipboard.writeText(this.output)
                alert('کد آی فریم کپی شد')
            },
            async submit() {
                this.state.loading = true
                if (typeof this.id === "undefined") {
                    const res = await this.$axios.post('/pages', this.formData)
                    this.formData = res.data.data
                    this.$error('راهنما با موفقیت ایجاد شد', '', 'success')
                    this.$router.replace({name:'ShowHelp' ,params:{id: this.formData.id}})
                }else{
                    const res = await this.$axios.patch('/pages/'+ (this.id || this.formData.id), this.formData)
                    this.formData = res.data.data
                    this.$error('راهنما با موفقیت تغییر کرد', '', 'success')
                }

            },
            async getData() {
                this.state.loading = true
                const res = await this.$axios.get('/pages/' + this.id)
                this.formData = res.data.data
            }
        },
        mounted() {
            if (typeof this.id !== "undefined") {
                this.getData()
            }
        }

    }
</script>
<style lang="scss">

    .custom-file-label::after {
        content: 'انتخاب فایل' !important;
    }

    .fr-box {
        /*text-align: left;*/
        p,ul,li,ol {
            text-align: left;
            font-family: 'Yekan', 'IRANSans', sans-serif !important;
        }

    }

</style>
